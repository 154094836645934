.main-tab{
    position: relative;
    width: 100vw;
    height: 100vh;
}
.music-btn{
    position: fixed;
    right: 24px;
    top: 24px;
    background: url(../../assets/img/music-btn.png) no-repeat;
    background-size: cover;
    width: 3.020833vw;
    height: 3.020833vw;
    z-index: 10;
    cursor: pointer;
    animation: btnRotate 4.5s 0s linear infinite;
    &.stop{
        animation-play-state: paused;
    }
}
@keyframes btnRotate{
    from {
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
.page-style{
    width: 100%;
    height: 100%;
    background: url(../../assets/img/bg.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen0{
    
    display: flex;
    align-items: center;
    justify-content: center;
    .text{
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: calc(-50vw);
        background: url(../../assets/img/sec1-text.png) no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        // background-color: #ff0;
        mix-blend-mode: soft-light;
      
    }
}
.number2{
    background: url(../../assets/img/num2.png) no-repeat;
    width: 8.2291vw;
    height: 12.2916vw;
    background-size: cover;
}
.screen1{
    background: url(../../assets/img/sec2.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen2{
    background: url(../../assets/img/sec3.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen3{
    background: url(../../assets/img/sec4.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen4{
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.screen5{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .superman-text{
        position: absolute;
        top: 50%;
        margin-top: -4.8177vw;
        width: 90vw;
        height: 9.6354vw;
        transform-style: preserve-3d;
        transform-origin: 50% 50% -45vw;
        transform: rotateY(0deg);
        transition: all 0.5s 0s ease;
        transition-property: transform;
        div{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            backface-visibility:hidden;
            
        }
        .text-left{
            transform-origin: right center;
            transform: rotateY(-90deg);
            left: -100%;
            background: url(../../assets/img/sec6-text1.png) 50% no-repeat;
            background-size: 57.5vw 9.0104vw;
        }
        .text-center{
            background: url(../../assets/img/sec6-text3.png) 50% no-repeat;
            background-size: 83.3854vw 7.2916vw;
        }
        .text-right{
            transform-origin: left center;
            transform: rotateY(90deg);
            right: -100%;
            background: url(../../assets/img/sec6-text2.png) 50% no-repeat;
            background-size: 90vw 9.6354vw;
        }
    }
}
.superman-logo{
    background: url(../../assets/img/superman-logo.png) no-repeat;
    width: 68.5416vw;
    height: 52.9166vw;
    background-size: cover;
}
.screen6{
    overflow: hidden;
    background: url(../../assets/img/sec6.jpg) no-repeat 50% 50%;
    background-size: cover;
    .super-man{
        position: absolute;
        left: -2.08333vw;
        top: 0;
        background: url(../../assets/img/sec6-img.png) no-repeat;
        width: 40.8854vw;
        height: 50.3125vw;
        background-size: cover;
        z-index: 1;
    }
    .super-man-text{
        position: absolute;
        left: 32.39583vw;
        top: 10.9375vw;
        background: url(../../assets/img/sec6-text.png) no-repeat;
        background-size: cover;
        width: 54.2187vw;
        height: 30.625vw;
        z-index: 2;
    }
}

.screen7{
    background: url(../../assets/img/sec7.jpg) no-repeat 50% 50%;
    background-size: cover;
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.screen8{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/img/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
        mix-blend-mode:color-dodge;
        z-index: 2;
    }
    .mask{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/img/gz-mask.png) no-repeat 50% 50%;
        background-size: cover;
        z-index: 3;
    }
    .superman-text{
        position: absolute;
        top: 50%;
        margin-top: -4.8177vw;
        width: 89.84375vw;
        height: 7.2916vw;
        transform-style: preserve-3d;
        transform-origin: 50% 50% (-89.84375vw/2);
        transform: rotateY(0deg);
        transition: all 0.5s 0s ease;
        transition-property: transform;
        z-index: 4;
        div{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            backface-visibility:hidden;
            
        }
        .text-left{
            transform-origin: right center;
            transform: rotateY(-90deg);
            left: -100%;
            background: url(../../assets/img/sec8-text-left.png) 50% no-repeat;
            background-size: 75.677083vw 7.2916vw;
        }
        .text-center{
            background: url(../../assets/img/sec8-text-center.png) 50% no-repeat;
            background-size: 87.08333vw 6.875vw;
        }
        .text-right{
            transform-origin: left center;
            transform: rotateY(90deg);
            right: -100%;
            background: url(../../assets/img/sec8-text-right.png) 50% no-repeat;
            background-size: 89.84375vw 6.40625vw;
        }
    }
}
.screen9{
    background: url(../../assets/img/sec9.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen10{
    background: url(../../assets/img/sec10.jpg) no-repeat 50% 50%;
    background-size: cover;
}

.screen11{
    background: url(../../assets/img/sec11.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen12{
    background: url(../../assets/img/sec12.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen13{
    background: url(../../assets/img/sec13.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen14{
    background: url(../../assets/img/sec14.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen15{
    background: url(../../assets/img/sec15.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen16{
    background: url(../../assets/img/sec16.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen17{
    background: url(../../assets/img/sec17.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen18{
    background: url(../../assets/img/sec18.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen19{
    background: url(../../assets/img/sec19.jpg) no-repeat 50% 50%;
    background-size: cover;
}
.screen20{
    overflow: hidden;
    .color-blcok{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/img/red-mask.png) no-repeat;
        background-size: cover;
        mix-blend-mode: screen;
        z-index: 2;
        pointer-events: none;
    }
    .thankyou-wrap{
        position: relative;
        mix-blend-mode: screen;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .mask-before{
        position: absolute;
        left: 0;
        top: 0;
        // background: url(../../assets/img/mask2.png) -0.78125vw -3.1770vw no-repeat;
        // background-size: 100.78125vw 65.10416vw;
        width: 100vw;
        height: 100%;
        z-index: 2;
        .img-comm{
            position: absolute;
            background: url(../../assets/img/sec20-img.jpg) no-repeat;
            width: 36.875vw;
            // height: 56.1979vw;
            height: 100vh;
            background-size: cover;
            // mix-blend-mode: overlay;
            
            z-index: 2;
        }
        .mask{
            position: absolute;
            top: 0;
            background: url(../../assets/img/mask2.png) -0.78125vw -3.1770vw no-repeat;
            background-size: 100.78125vw 65.10416vw;
            width: 100vw;
            height: 56.25vw;
            mix-blend-mode: overlay;
            z-index: 3;
            pointer-events: none;
        }
        .img-comm2{
            position: absolute;
            background: url(../../assets/img/sec20-img2.jpg) no-repeat;
            background-size: cover;
            
            width: 68.4375vw;
            height: 104.27083vw;
            z-index: 1
        }
        .img-center{
            left: 30.7614vw;
            // mix-blend-mode: normal;
        }
        .img-left{
            left: -18.4375vw;
            top: -3.75vw;
        }
        .img-right{
            left: 59.7916vw;
            top: -41.40625vw;
        }
    }
    .thankyou-text{
        // position: absolute;
        position: relative;
        top: 0;
        z-index: 4;
        width: 51.4583vw;
        height: 5.8333vw;
        background: url(../../assets/img/sec-text.png) no-repeat;
        background-size: cover;
    }
}
